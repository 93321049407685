:root {
    --content-width: 60rem;
    --gutter-size: 0.5rem;
    --header-height: 3.25rem;
    --link-blue: #2a7ae2;
    --meta-grey: #828282;
    --title-grey: #424242;
    --visited-blue: #1756a9;
    --header-border: #e8e8e8;
}

@media (prefers-color-scheme: dark) {
    body {
        background: #121212;
        color: #fff;
    }
    :root {
        --header-border: #424242;
        --title-grey: #fff;
        --meta-grey: #e1e1e1;
    }
}

*,
:before,
:after {
    box-sizing: border-box;
}
body {
    font-family: Helvetica, Arial, sans-serif;
    margin: 0;
}
a {
    color: var(--link-blue);
    text-decoration: none;
}
a:hover, a:active {
    text-decoration: underline;
}
a:visited {
    color: var(--visited-blue);
}
pre, code {
    overflow: auto;
}
pre {
    padding: 0.5rem;
}
.Site-header {
    border-bottom: 1px solid var(--header-border);
    padding: 0 var(--gutter-size);
}
.Site-headerContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    max-width: var(--content-width);
}
.Site-title {
    color: var(--title-grey);
    font-size: 1.625rem;
    letter-spacing: -0.0625rem;
    line-height: var(--header-height);
    margin: 0;
}
.Site-titleLink, .Site-titleLink:visited, .Site-titleLink:active {
    color: var(--title-grey);
}
.Site-navList {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: var(--header-height);
    justify-content: space-evenly;
    list-style-type: none;
    margin: 0;
}
.Site-navItem + .Site-navItem {
    margin-left: 1rem;
}
.Content {
    padding: 0 var(--gutter-size);
    width: 100%;
}
.Home {
    margin: auto;
    max-width: var(--content-width);
}
.Single-meta {
    color: var(--meta-grey);
    font-size: 0.875rem;
}